<template>
	<div class="pa-4">
		<div v-if="!noDataFound">
			<div v-if="isLoading" color="grey lighten-4" class="pa-3">
				<v-row>
					<v-col cols="6" v-for="i in 4" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
					</v-col>
				</v-row>
			</div>
			<div v-else class="d-flex flex-column">
				<div>
					<v-btn
						style="float: right"
						class="mt-2 align-self-end mr-3"
						rounded
						dark
						color="primary"
						@click="createNew()"
						>+ Create Post</v-btn
					>
				</div>
				<v-row v-if="posts && posts.length > 0" class="mx-0">
					<v-col cols="6" v-for="post in posts" :key="post.name">
						<v-card
							class="d-flex justify-space-between flex-column"
							height="100%"
						>
							<div v-if="post.media" class="container">
								<div
									v-for="media in post.media"
									:key="media.name"
								>
									<v-img
										v-if="media.mediaFormat === 'PHOTO'"
										:src="media.googleUrl"
									></v-img>
								</div>
								<div class="top-left">
									Updated on {{ dateFormat(post.updateTime) }}
								</div>
							</div>
							<div
								class="updatedOn body-2 pa-3"
								v-if="!post.media"
							>
								Updated on {{ dateFormat(post.updateTime) }}
							</div>
							<p v-if="post.summary" class="mx-2 body-2 content">
								{{ post.summary }}
							</p>
							<!-- <p class="mx-2 font-weight-medium">Status - {{ post.topicType }}</p> -->
							<v-btn
								v-if="
									post.callToAction && post.callToAction.url
								"
								outlined
								class="ma-2"
								target="_blank"
								:href="post.callToAction.url"
							>
								{{
									post.callToAction.actionType.replace(
										'_',
										' '
									)
								}}
							</v-btn>
							<div>
								<v-divider></v-divider>
								<v-card-actions>
									<UnoEditButton
										@click.native="editPost(post)"
									></UnoEditButton>
									<v-spacer></v-spacer>
									<UnoDeleteButton
										@confirm="deletePost(post.name)"
									></UnoDeleteButton>
								</v-card-actions>
							</div>
						</v-card>
					</v-col>
				</v-row>
				<div v-if="showPostDrawer">
					<CreatePostDrawer
						@close-drawer="closeDrawer($event)"
						@on-post-update="updatePost($event)"
						:initial-data="post"
						:saving="saving"
					></CreatePostDrawer>
				</div>
			</div>
			<div class="d-flex flex-row justify-center ma-2">
				<v-btn
					@click="getPosts(previousPageToken, true, false)"
					:disabled="!previousPageToken"
					:loading="isLoading"
					class="mr-2"
				>
					<span class="mdi mdi-arrow-left-bold"></span
				></v-btn>
				<v-btn
					@click="getPosts(nextPageToken, false, true)"
					:disabled="!nextPageToken"
					:loading="isLoading"
					><span class="mdi mdi-arrow-right-bold"></span
				></v-btn>
			</div>
		</div>
		<NoData v-else></NoData>
	</div>
</template>

<script>
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import NoData from '../../../components/NoData'
import CreatePostDrawer from './CreatePostDrawer'

export default {
	components: {
		UnoEditButton,
		UnoDeleteButton,
		CreatePostDrawer,
		NoData,
	},
	data() {
		return {
			page: 1,
			repeat: false,
			pageTokens: [],
			nextPageToken: null,
			previousPageToken: null,
			summary: '',
			url: '',
			media: '',
			name: null,
			address: null,
			drawer: false,
			showPostDrawer: false,
			post: {},
			isLoading: false,
			saving: false,
			posts: [],
			noDataFound: false,
		}
	},
	created() {
		this.getPosts()
	},
	computed: {
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	methods: {
		getPosts(nextPageToken, previous, next) {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			let payload = {
				name: this.locationName,
				email: this.locationEmail,
				pageToken: nextPageToken ? nextPageToken : null,
			}
			if (nextPageToken === 'last') payload.pageToken = null
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/posts`,
				data: payload,
			})
				.then((response) => {
					this.isLoading = false
					this.$nextTick(function () {
						this.posts = response?.data?.data?.posts
						this.posts
							? (this.noDataFound = false)
							: (this.noDataFound = true)
					})
					if (next) {
						response?.data?.data?.nextPageToken
							? this.pageTokens.push(
									response?.data?.data?.nextPageToken
							  )
							: null
						this.previousPageToken =
							this.pageTokens?.length <= 1
								? null
								: this.pageTokens[this.pageTokens?.length - 2]
					}
					this.nextPageToken = response?.data?.data?.nextPageToken
						? response?.data?.data?.nextPageToken
						: null
					if (previous && this.previousPageToken) {
						this.pageTokens.splice(this.pageTokens.length - 1, 1)
					}
					this.previousPageToken =
						this.pageTokens.length === 0
							? null
							: this.pageTokens[this.pageTokens.length - 2]
					if (this.repeat) {
						this.previousPageToken =
							this.pageTokens.length === 1
								? 'last'
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.repeat = true
				})
				.catch((err) => {
					if (err.response.status == 404) this.noDataFound = true
					this.isLoading = false
					this.$snackbar.notify({
						message: err?.response?.data?.message,
						color: 'error',
					})
				})
		},
		updatePost(postData) {
			const projectId = this.$store.state.app.projectId
			let payload = {}
			let url = ``
			if (postData.scheduledTime) {
				this.isScheduledPost = true
				url = `/${projectId}/gmb/post/schedule`
				payload = {
					name: this.locationName,
					email: this.locationEmail,
					post: {
						summary: postData.summary,
						media: postData.media,
						callToAction: postData.callToAction,
					},
					scheduledTime: postData.scheduledTime,
				}
			} else {
				url = postData.postName
					? `/${projectId}/gmb/post/edit`
					: `/${projectId}/gmb/post`
				payload = {
					name: this.locationName,
					email: this.locationEmail,
					summary: postData.summary,
					media: postData.media,
					callToAction: postData.callToAction,
				}
			}
			this.saving = true
			return this.axios({
				method: 'post',
				url,
				data: payload,
			})
				.then((response) => {
					this.saving = false
					this.showPostDrawer = false
					if (response && response.status === 200) {
						if (postData.scheduledTime) {
							this.$snackbar.notify({
								message:
									'Post scheduled successfully. You can check it in Scheduled Posts section',
							})
						} else {
							this.$snackbar.notify({
								message: 'Posts published successfully.',
								color: 'green',
							})
						}
						if (!postData.scheduledTime) {
							setTimeout(() => {
								this.getPosts()
							}, 0)
						}
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
					this.saving = false
				})
		},
		deletePost(name) {
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/post/delete`,
				data: {
					name,
					email: this.$cookies.get('gmb-account-email'),
				},
			})
				.then((response) => {
					if (response?.data?.message) {
						this.$snackbar.notify({
							message: response?.data?.message,
							color: 'yellow',
						})
						setTimeout(() => {
							this.getPosts()
						}, 0)
					}
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		editPost(postData) {
			this.post = postData
			this.showPostDrawer = true
		},
		closeDrawer() {
			this.showPostDrawer = !this.showPostDrawer
		},
		createNew() {
			this.post = {}
			this.showPostDrawer = true
		},
	},
}
</script>

<style scoped>
.content {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.footer {
	background-color: var(--blue);
	height: 60px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
.container {
	position: relative;
	text-align: center;
	color: white;
	padding: 0px !important;
}
.top-left {
	position: absolute;
	top: 0px;
	background: linear-gradient(rgba(0, 0, 0, 0.54), transparent);
	height: 150px;
	color: #f1f1f1;
	width: 100%;
	padding: 20px;
	text-align: left;
}
</style>
