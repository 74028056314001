<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="post-drawer"
	>
		<div class="d-flex align-center header pt-4 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="closeDrawer()"
			></span>
			<h1 class="crud-title text-center">Create Post</h1>
		</div>
		<div class="pa-4">
			<FileUploader
				:initFiles="banner"
				@errorOccured="dropzoneError"
				:purpose="`GMB`"
				:maxFiles="10"
				@changed="fileUploaderChanged"
			/>
			<v-carousel v-if="media && media.length > 0" class="mb-9">
				<v-carousel-item
					v-for="image in media"
					:key="image.name"
					:src="image.googleUrl ? image.googleUrl : image.publicURL"
					reverse-transition="fade-transition"
					transition="fade-transition"
				></v-carousel-item>
			</v-carousel>
			<v-textarea
				name="address"
				label="Write Your Post"
				no-resize
				@input="$v.summary.$touch()"
				@blur="$v.summary.$touch()"
				:error-messages="summaryErrors()"
				required
				outlined
				v-model="summary"
			></v-textarea>
			<p>Add a button (optional)</p>
			<v-select
				:items="callToActionsOptions"
				dense
				v-model="callToAction.actionType"
			></v-select>
			<div
				v-if="
					callToAction.actionType &&
					callToAction.actionType !== 'NONE' &&
					callToAction.actionType !== 'CALL'
				"
				class="mt-5"
			>
				<p class="pb-3">Link for Your Button</p>
				<v-text-field
					v-model="callToAction.url"
					placeholder="Enter the url for the button"
					outlined
					persistent-hint
					hint="Eg- www.unosearch.net"
					dense
					:error-messages="urlError"
				></v-text-field>
			</div>
			<v-row class="mx-0">
				<v-col cols="12" class="pa-0">
					<v-checkbox
						v-model="scheduledPost"
						label="Schedule Post"
					></v-checkbox>
				</v-col>
				<v-col cols="6" class="pa-0 pr-2 d-flex" v-if="scheduledPost">
					<v-date-picker
						v-model="date"
						:min="minDate"
					></v-date-picker>
				</v-col>
				<v-col
					cols="6"
					class="pa-0 d-flex"
					v-if="scheduledPost && date"
				>
					<v-time-picker
						v-if="date"
						v-model="time"
						color="green lighten-1"
						header-color="blue"
						:min="minTime"
					></v-time-picker>
				</v-col>
			</v-row>
			<div class="d-flex flex-row justify-end actions">
				<v-btn
					@click="savePost()"
					depressed
					class="save"
					:loading="saving"
					>{{
						scheduledPost ? 'Schedule Post' : 'Publish Now'
					}}</v-btn
				>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import moment from 'moment'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
	data() {
		return {
			minDate: moment(new Date()).format('YYYY-MM-DD'),
			time: null,
			date: null,
			scheduledPost: false,
			summary: null,
			media: [],
			callToAction: {
				actionType: null,
				url: null,
			},
			callToActionsOptions: [
				'NONE',
				'BOOK',
				'ORDER',
				'SHOP',
				'LEARN_MORE',
				'SIGN_UP',
				'CALL',
			],
			drawer: true,
			urlError: null,
			banner: [],
			isEdit: false,
			haveErrors: false,
		}
	},
	components: {
		FileUploader,
	},
	props: {
		initialData: {
			type: Object,
			required: true,
			default: () => {
				return {}
			},
		},
		scheduledTimeStamp: {
			type: String,
			required: false,
			default: null,
		},
		postId: {
			type: String,
			required: false,
			default: null,
		},
		saving: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		if (this.initialData && this.initialData.summary) {
			this.isEdit = true
			this.callToActionsOptions.splice(0, 1) //delete NONE option
			this.summary = this.initialData.summary
			this.media = this.initialData.media ? this.initialData.media : []
			this.callToAction.actionType =
				this.initialData.callToAction &&
				this.initialData.callToAction.actionType
					? this.initialData.callToAction.actionType
					: null
			this.callToAction.url =
				this.initialData.callToAction &&
				this.initialData.callToAction.url
					? this.initialData.callToAction.url
					: null
		}
		if (this.scheduledTimeStamp) {
			const date = moment(this.scheduledTimeStamp).format('YYYY-MM-DD')
			const time = this.scheduledTimeStamp.split('T')[1].substring(0, 5)
			this.date = date
			this.time = time
			this.scheduledPost = true
		}
	},
	watch: {
		url() {
			const expression =
				// eslint-disable-next-line no-useless-escape
				/www\.([A-z]+)\.([A-z]{2,})/gi
			const regex = new RegExp(expression)
			if (this.url && !this.url.match(regex)) {
				this.urlError =
					'Please enter complete URL for the button. For eg- "www.unosearch.net"'
			} else {
				this.urlError = null
			}
		},
	},
	validations: {
		summary: {
			required,
			minLength: minLength(5),
		},
	},
	computed: {
		url() {
			return this.callToAction.url
		},
		minTime() {
			if (this.date === moment(new Date()).format('YYYY-MM-DD')) {
				return moment(new Date()).format('HH:mm')
			}
			return '00:00'
		},
		scheduledTime() {
			return this.date + 'T' + this.time + ':00.000Z'
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	methods: {
		summaryErrors() {
			let errors = []
			if (!this.$v.summary.$dirty) return errors

			!this.$v.summary.minLength &&
				errors.push('Summary must be atleast 5 letters long')
			!this.$v.summary.required && errors.push('Summary is required.')
			if (errors.length > 0) this.haveErrors = true
			else this.haveErrors = false
			return errors
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				this.banner = val
				this.media = val
			} else {
				this.banner = []
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		closeDrawer() {
			this.$emit('close-drawer')
		},
		savePost() {
			if (!this.haveErrors) {
				this.urlError = null
				if (this.banner.length > 0) {
				console.log(this.banner)
					this.media = this.banner.map((obj) => ({
						sourceUrl: obj.publicURL,
						mediaFormat: obj.publicURL.includes('.mp4')? 'VIDEO':
						'PHOTO',
					}))
				}
				if (
					this.callToAction.actionType !== 'CALL' &&
					this.callToAction.actionType !== 'NONE' &&
					this.callToAction.actionType !== null &&
					!this.callToAction.url
				) {
					this.urlError = 'Please enter the Url for the button.'
				}
				if (this.callToAction.actionType === 'NONE') {
					this.callToAction.actionType = null
					this.callToAction.url = null
				}
				if (this.callToAction.actionType === 'CALL') {
					this.callToAction.url = null
				}
				if (!this.urlError) {
					let payload = {}
					if (this.date && this.time) {
						payload = {
							summary: this.summary,
							postName: this.initialData.name
								? this.initialData.name
								: null,
							scheduledTime: this.scheduledTime,
							media: this.media,
							id: this.postId,
						}
					} else {
						payload = {
							summary: this.summary,
							postName: this.initialData.name
								? this.initialData.name
								: null,
							email: this.locationEmail,
						}
					}
					if (!this.isEdit && this.banner.length > 0) {
						//when creating new post
						payload.media = this.media
					}
					if (this.callToAction.actionType) {
						payload.callToAction = {
							actionType: this.callToAction.actionType,
							url: this.callToAction.url,
						}
					}
					this.$emit('on-post-update', payload)
				}
			}
		},
	},
}
</script>

<style scoped>
.post-drawer {
	width: 50% !important;
	height: 100% !important;
	overflow-y: auto;
}
.post-drawer .header {
	border-bottom: 1px solid #eee;
}
.post-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.post-drawer .crud-title {
	width: 100%;
}
.post-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
}
</style>
